import { Badge, Group } from "@mantine/core";

import {
  CLIENT,
  CREATED_AT,
  CREATOR,
  PRICE_TTC,
  PRODUCTS,
  SERVICES,
  STATUS,
  TAXI,
} from "Constant";

import { getCodeLabel } from "helper/getOptions";

import {
  ConfirmBtn,
  ConfirmPaymentBtn,
  CreateDelivery,
  CreateDeliveryFormBtn,
  PrepareDeliveryFormBtn,
  ReadyDeliveryFormBtn,
} from "./PurchaseOrderBtns";
import ReadSheetBtn from "Components/MantineDataTableBtns/ReadSheetBtn";
import { createdAt, updatedAt } from "Constant/objs";

import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";

// API
export const API = {
  list: "/back/purchaseorders",
};

// Columns
export const COLUMNS = [
  {
    name: "N° purchase order",
    selector: "po_number",
  },

  {
    accessorFn: (originalRow) => originalRow.estimate_number,
    id: "estimate_number",
    header: "N° estimate",

    Cell: ({ row }) => {
      const { estimate_number, estimate_code } = row.original;
      const path = `/estimate/${estimate_code}`;
      if (estimate_code)
        return <ReadSheetBtn read={path} title={estimate_number} />;
    },
  },

  {
    accessorFn: (originalRow) => originalRow.status,
    id: "status",
    header: STATUS,

    filterFn: "equals",
    filterVariant: "select",
    mantineFilterSelectProps: {
      data: [
        { value: "canceled", label: "Annulé" },
        { value: "draft", label: "Brouillon" },
        { value: "confirmed", label: "Confirmé" },
        { value: "paid", label: "Payé" },
        { value: "created", label: "Crée" },
        { value: "ready", label: "Prêt" },
      ],
    },

    Cell: ({ row }) => {
      const { status } = row.original;

      const color = getColorFromStatus(status);

      return (
        <Badge color={color} size="lg">
          {status}
        </Badge>
      );
    },
  },

  { name: PRODUCTS, selector: "count_products" },
  { name: SERVICES, selector: "count_services" },

  {
    name: PRICE_TTC,
    selector: "price_ttc",
  },
  {
    header: CLIENT,
    accessorFn: (originalRow) => originalRow.client,
    id: "client",

    size: 300,

    Cell: ({ row }) => {
      const { client, code_client } = row.original;
      const path = `/client/${code_client}`;

      return <ReadSheetBtn read={path} title={client} />;
    },
  },
  {
    header: TAXI,
    accessorFn: (originalRow) => originalRow.taxi,
    id: "taxi",

    size: 300,

    Cell: ({ row }) => {
      const { taxi, code_taxi } = row.original;
      const path = `/taxi/${code_taxi}`;

      return <ReadSheetBtn read={path} title={taxi} />;
    },
  },
  {
    name: CREATOR,
    selector: "creator",
  },
  createdAt,
  updatedAt,

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 220,
    Cell: ({ row }) => {
      const { code, to_confirm, to_prepare, to_deliver, to_ready } =
        row.original;

      const confirmPaymentUrl = `/back/purchaseorder/${code}/confirm`;
      const createDeliveryFormUrl = `/back/purchaseorder/${code}/deliveryform`;
      const urlPrepare = `/back/purchaseorder/${code}/prepare`;
      const urlReady = `/back/purchaseorder/${code}/ready`;

      return (
        <Group gap="xs">
          <ReadPageBtn read={`/purchase-order/${code}`} />
          <CreateDeliveryFormBtn
            url={createDeliveryFormUrl}
            disabled={!to_deliver}
            data={row.original}
          />
          <ConfirmPaymentBtn url={confirmPaymentUrl} disabled={!to_confirm} />
          <ReadyDeliveryFormBtn url={urlReady} disabled={!to_ready} />
          <PrepareDeliveryFormBtn url={urlPrepare} disabled={!to_prepare} />
        </Group>
      );
    },
  },
];

const getColorFromStatus = (status) => {
  const statusColors = {
    Annulé: "red",
    Brouillon: "gray",
    Confirmé: "green",
    Payé: "indigo",
    "En preparation": "yellow",

    "En livraison": "orange",
    Pret: "blue",
    Clôturé: "cyan",
  };

  return statusColors[status] || "grape"; // Default color if status doesn't match
};
