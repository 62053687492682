import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";

import { getFullNameCode } from "helper/getOptions";

import { Badge, Group } from "@mantine/core";
import { SettingsBtn } from "./SettingsBtn";
import {
  EXPECTED_FINISH_DATE,
  N_WORK_ORDER,
  PURCHASE_ORDER,
  SCHEDULED_FOR,
  STATUS,
} from "Constant";
import ReadSheetBtn from "Components/MantineDataTableBtns/ReadSheetBtn";
import SelectBar from "Components/FilterInputs/SelectBar";

// Urls
export const API = {
  list: "/back/workOrders",
  create: "/back/workOrder",
  get: "/back/workOrder/",
  edit: "/back/workOrder",
  delete: "/back/workOrder/",
  changeStatus: (code, newStatus) => `/back/workOrder/${code}/${newStatus}`,
};

const clorAndLabel = {
  canceled: { label: "Annulé", color: "red" },
};

const getColorFromStatus = (status_code) => {
  const statusColors = {
    canceled: "red",
    draft: "yellow",
    confirmed: "green",
    in_preparation: "orange",
    ready: "cyan",
    closed: "indigo",
  };
  return statusColors[status_code] || "gray";
};

// Columns
export const COLUMNS = [
  { name: N_WORK_ORDER, selector: "wo_number" },

  {
    accessorFn: (originalRow) => originalRow.po_number,
    id: "po_number",
    header: PURCHASE_ORDER,

    Cell: ({ row }) => {
      const { po_number, po_code } = row.original;
      const path = `/purchase-order/${po_code}`;
      if (po_code) return <ReadSheetBtn read={path} title={po_number} />;
    },
  },
  {
    accessorFn: (originalRow) => originalRow.status_label,
    id: "status",
    header: STATUS,

    // filterFn: "equals",
    Filter: ({ column, header, table }) => {
      try {
        return (
          <SelectBar
            url={"/back/statuses/work_order"}
            column={column}
            header={header}
            table={table}
          />
        );
      } catch {
        return <></>;
      }
    },

    Cell: ({ row }) => {
      const { status_code, status_label } = row.original;

      return (
        <Badge color={getColorFromStatus(status_code)} size="lg">
          {status_label}
        </Badge>
      );
    },
  },

  { name: "Creator", selector: "creator" },

  { name: "Executor", selector: "executor" },
  { name: "Created at", selector: "created_at" },
  { name: EXPECTED_FINISH_DATE, selector: "expected_finish_date" },

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 80,
    Cell: ({ row }) => {
      const { code } = row.original;
      return (
        <Group gap="xs">
          <ReadPageBtn read={`/work-order/${code}`} />
          <SettingsBtn data={row.original} />
        </Group>
      );
    },
  },
];
// Form

export const CREATE_ITEMS = [
  {
    title: "Executor",
    name: "executor",
    inputForm: "SelectApiInput",
    optionsApi: "/back/executors",
    getOptions: getFullNameCode,
  },
];
export const EDIT_ITEMS = [
  {
    title: "Executor",
    name: "executor",
    inputForm: "SelectApiInput",
    optionsApi: "/back/executors",
    getOptions: getFullNameCode,
  },
  {
    title: SCHEDULED_FOR,
    placeholder: "Scheduled for",
    name: "expectedFinishDate",
    inputForm: "DateInput",
    required: false,
  },
  {
    title: "Description",
    placeholder: "Description",
    name: "description",
    inputForm: "TextareaInput",
    required: false,
  },
];
